const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="block">
          <a href="tel: +375 29 628 86 82">+375 29 628 86 82</a>
          <p>
            Кальварийская 42, БЦ Каскад Альфа <br /> м. Молодежная
          </p>
        </div>

        <div class="block">
          <a className="logo" href="/">
            компания МАРИЭЛТ
          </a>
          <div class="row">
            <a href="https://instagram.com/marielt_an?igshid=NzZlODBkYWE4Ng== ">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM12 18.9081C12 15.0988 15.0992 12 18.9081 12H29.0919C32.9012 12 36 15.0992 36 18.9081V29.1853C36 32.9942 32.9012 36.0934 29.0919 36.0934H18.9081C15.0988 36.0934 12 32.9942 12 29.1853V18.9081ZM17.7544 24.0026C17.7544 20.5847 20.565 17.8043 24.0191 17.8043C27.4731 17.8043 30.2837 20.5847 30.2837 24.0026C30.2837 27.4201 27.4731 30.2005 24.0191 30.2005C20.5646 30.2005 17.7544 27.4201 17.7544 24.0026ZM20.0363 24.0026C20.0363 26.1616 21.8228 27.9186 24.0191 27.9186C26.2153 27.9186 28.0019 26.162 28.0019 24.0026C28.0019 21.8432 26.215 20.0865 24.0191 20.0865C21.8228 20.0865 20.0363 21.8432 20.0363 24.0026ZM30.3959 19.025C31.21 19.025 31.8699 18.3651 31.8699 17.551C31.8699 16.737 31.21 16.077 30.3959 16.077C29.5818 16.077 28.9219 16.737 28.9219 17.551C28.9219 18.3651 29.5818 19.025 30.3959 19.025Z"
                  fill="#E3C496"
                />
              </svg>
            </a>

            <a href="https://www.tiktok.com/@marielt_n">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24 48C37.2547 48 48 37.2547 48 24C48 10.7453 37.2547 0 24 0C10.7453 0 0 10.7453 0 24C0 37.2547 10.7453 48 24 48ZM26.6376 12.0067C25.8912 12.0077 25.1434 12.0086 24.3931 12.0202L24.3936 12.0211C24.3744 14.5987 24.3754 17.1773 24.3768 19.7587C24.3773 21.2021 24.3782 22.6459 24.3749 24.0912C24.372 24.576 24.3725 25.0603 24.373 25.5442C24.3739 26.8459 24.3754 28.1462 24.3038 29.4504C24.2933 29.8123 24.1118 30.1306 23.9357 30.4392L23.8934 30.5131C23.3117 31.4621 22.2413 32.1101 21.1243 32.1221C19.4395 32.2709 17.8618 30.8918 17.6242 29.2507C17.6222 29.1706 17.6194 29.0894 17.6165 29.0083C17.6002 28.5456 17.5834 28.0733 17.7595 27.6422C18.0101 26.9309 18.491 26.3026 19.1242 25.8922C19.991 25.2898 21.1507 25.2 22.1434 25.5211C22.1434 24.8885 22.1539 24.2558 22.1645 23.6237C22.1789 22.7755 22.1933 21.9274 22.1818 21.0782C20.0098 20.6722 17.6899 21.359 16.0339 22.8014C14.5728 24.0398 13.6315 25.86 13.4515 27.7627C13.4335 28.2587 13.4375 28.7551 13.4635 29.2507C13.6704 31.5912 15.0917 33.7714 17.1115 34.9627C18.3307 35.6808 19.7592 36.071 21.1896 35.9894C23.5214 35.9501 25.7918 34.7002 27.0998 32.7821C27.913 31.6301 28.3742 30.2386 28.4525 28.8408C28.4726 26.9203 28.4717 24.996 28.4698 23.0698C28.4698 22.0771 28.4688 21.0835 28.4712 20.0899C28.9906 20.4298 29.5219 20.7619 30.0917 21.0202C31.4006 21.6408 32.8498 21.9413 34.2917 21.9883V17.9611C32.7528 17.7898 31.1712 17.2819 30.0533 16.1726C28.9325 15.0902 28.3819 13.5307 28.3032 12C27.7483 12.0048 27.1934 12.0058 26.6376 12.0067Z"
                  fill="#E3C496"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
