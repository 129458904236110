import { React, useState, useEffect, useRef } from "react";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px" }}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: `<svg width="20" height="36" viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.56946 2.57144L17.998 18L2.56946 33.4286" stroke="#E3C496" stroke-width="2.57143" stroke-linecap="square" />
    </svg>`,
      }}
    />
  );
};

export default NextArrow;
