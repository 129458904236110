import LazyImage from "./LazyImage";
import NextArrow from "./Next-arrow";
import Slider from "react-slick";
import { React, useState, useEffect, useRef } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: `<svg width="20" height="36" viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0011 2.57144L2.57255 18L18.0011 33.4286" stroke="#868686" stroke-width="2.57143" stroke-linecap="square" />
          </svg>`,
      }}
    />
  );
};

const SliderSection = () => {
  const blocks = [
    {
      img: "profitProcent.png",
      title: "Выгодные проценты для агента и комфортные условия труда",
      description: `Конкурентоспособные проценты для тех, кто нацелен на результат.
              Создаем продуктивную атмосферу и предоставляем все для работы.`,
    },
    {
      img: "trening.png",
      title:
        "Регулярные тренинги по продажам, психологии и юридическим вопросам",
      description:
        "Мы помогаем вам все время повышать свою экспертизу и развиваться, становясь специалистом высокого уровня и востребованным на рынке.",
    },
    {
      img: "psyscience.png",
      title: "Корпоративный коуч-психолог",
      description:
        "Помогаем ставить амбициозные цели и справляться с прокрастинацией, усиливая свои сильные стороны для личностного и профессионального роста.",
    },
    {
      img: "crm.png",
      title: "Реальная база покупателей в CRM-системе",
      description:
        "Взял объект — сразу нашел покупателей! Максимально сокращая цикл сделки и показывая клиенту результат, за который он рад платить.",
    },
    {
      img: "mac.png",
      title: "Конкурсы и регулярные розыгрыши IPhone и MacBook",
      description:
        "Достойные призы за достойные достижения. Несколько раз в год мы дарим подарки лучшим агентам в соревнованиях.",
    },
    {
      img: "teambuilding.png",
      title: "Тимбилдинги и эффектные корпоративы",
      description:
        "Два в год, за счет компании, мы устраиваем масштабный отдых с широким размахом",
    },
    {
      img: "kurier.png",
      title: "Курьер для сбора справок и документов",
      description:
        "Агент должен продавать, а бумажную волокиту можно делегировать, не отвлекаясь от работы, которая приносит прибыль.",
    },
    {
      img: "credit.png",
      title: "Кредитный сотрудник, регистратор сделок с БТИ прямо в офисе",
      description:
        "Приводи покупателя в офис и оформи все на месте, а ваши клиенты обязательно оценят такой сервис",
    },
    {
      img: "lid.png",
      title: "Предоставляем входящие лиды",
      description:
        "Отдел маркетинга создает поток входящих заявок от продавцов и покупателей, которые мы даем агентам",
    },
    {
      img: "clubs.png",
      title: "Клубы по интересам",
      description:
        "Мы играем в футбол, мафию, покер, денежный поток и многое другое! Приходи и найди себе компанию по интересам для качественного отдыха после работы!",
    },
    {
      img: "conf.png",
      title: "Конференции с представителями гос.органов",
      description:
        "Прямое обучение иформат вопрос-ответ напрямую от напрямую от представителей Белгосстраха, палаты адвокатов, сотрудника нотариальной конторы",
    },
    {
      img: "polis.png",
      title: "Страховой полис на 1 миллион рублей",
      description:
        "+ сильная юридическая поддержка. Вы точно можете быть спокойны и уверены, что сделка состоится и ничто не пойдет не по плану",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {blocks.map((block, index) => {
        if (index % 2 === 0) {
          return (
            <div className="slide" key={index}>
              <div className="block">
                <LazyImage
                  src={process.env.PUBLIC_URL + "/img/" + block.img}
                  alt=""
                />
                <div className="textPart">
                  <h3>{block.title}</h3>
                  <p>{block.description}</p>
                </div>
              </div>
              {blocks[index + 1] && (
                <div className="block">
                  <LazyImage
                    src={
                      process.env.PUBLIC_URL + "/img/" + blocks[index + 1].img
                    }
                    alt=""
                  />
                  <div className="textPart">
                    <h3>{blocks[index + 1].title}</h3>
                    <p>{blocks[index + 1].description}</p>
                  </div>
                </div>
              )}
            </div>
          );
        }
        return null;
      })}
    </Slider>
  );
};

const ProfitSection = () => {
  return (
    <section className="profit" id="secondSection">
      <h2>Преимущества работы с Мариэлт</h2>
      <SliderSection />
    </section>
  );
};

export default ProfitSection;
