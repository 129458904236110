import { React, useState, useEffect, useRef } from "react";

//CSS

import "../css/reset.css";
import "../css/landing.css";

//Sections
import HomeSection from "./components/HomeSection";
import ProfitSection from "./components/ProfitSection.jsx";
import CareerSection from "./components/CareerSection.jsx";
import StartSection from "./components/StartSection.jsx";
import QuestionSection from "./components/QuestionSection.jsx";
import CompanyHistory from "./components/CompanyHistory.jsx";
import FormSection from "./components/FormSection.jsx";
import Footer from "./components/Footer.jsx";

function Landing() {
  return (
    <>
      <main>
        <HomeSection />
        <ProfitSection />
        <CareerSection />
        <StartSection />
        <QuestionSection />
        <CompanyHistory />
        <FormSection />
      </main>

      <Footer />
    </>
  );
}

export default Landing;
