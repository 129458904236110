import { React, useState, useEffect, useRef } from "react";
import LazyImage from "./LazyImage";

const CompanyHistory = () => {
  const Images = [
    { src: "h1.png" },
    { src: "h2.png" },
    { src: "h4.png" },
    { src: "h3.png" },
    { src: "h5.png" },
    { src: "h6.png" },
  ];

  // Group images into pairs
  const imagePairs = [];
  for (let i = 0; i < Images.length; i += 2) {
    imagePairs.push(Images.slice(i, i + 2));
  }

  return (
    <section className="history">
      <h2>История компании в фото</h2>
      <div className="container">
        {imagePairs.map((pair, index) => (
          <div className="column" key={index}>
            {pair.map((img, idx) => (
              <LazyImage
                key={idx}
                src={process.env.PUBLIC_URL + "/img/" + img.src}
                alt=""
              />
            ))}
          </div>
        ))}
      </div>

      <a href="https://instagram.com/marielt_an?igshid=NzZlODBkYWE4Ng==">
        Наш Instagram{" "}
        <LazyImage
          src={process.env.PUBLIC_URL + "/img/arrow-right.svg"}
          alt=""
        />
      </a>
    </section>
  );
};

export default CompanyHistory;
