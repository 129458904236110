import { React, useState, useEffect, useRef } from "react";

const StartSection = () => {
  return (
    <div className="start">
      <div className="container">
        <div className="left">
          <h2>
            Старт программы – <span className="mainColor">декабрь 2024</span>
          </h2>
          <p>
            Успей занять свое место в роли успешного руководителя, т.к.
            следующий поток запуститься только через год!
          </p>
        </div>
        <div className="right">
          <p>
            Программа развития руководителей включает в себя обучение от
            профессиональных бизнес-тренеров.
          </p>
        </div>
      </div>
    </div>
  );
};
export default StartSection;
