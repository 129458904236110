import { React, useState, useEffect, useRef } from "react";
import LazyImage from "./LazyImage";

const CareerSection = () => {
  return (
    <section className="career">
      <div className="container">
        <div className="left">
          <h2>Какие карьерные перспективы?</h2>
          <LazyImage
            className="mobile"
            src={process.env.PUBLIC_URL + "/img/mobile_career.png"}
            alt=""
          />

          <h3>
            Если ты уже провел много сделок и знаешь рынок недвижимости –
            приходи!
          </h3>

          <p>
            Мы научим эффективному управлению, дадим стажеров, которые уже на
            этапе обучения совершают сделки и поможем стать крутым
            руководителем!
          </p>
        </div>

        <div className="right">
          <LazyImage src={process.env.PUBLIC_URL + "/img/career.png"} alt="" />
        </div>
      </div>
    </section>
  );
};

export default CareerSection;
